import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
//import RelatedProjects from '../components/CaseStudiesDetails/RelatedProjects'
import details1 from '../assets/images/projects/projects-details1.jpg'
import project2 from '../assets/images/projects/project2.jpg'
import {Link} from 'gatsby'
import icon1 from '../assets/images/services/service-icon1.png'
import icon2 from '../assets/images/services/service-icon2.png'
import icon3 from '../assets/images/services/service-icon3.png'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="DINO - Dino is the new ODK" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="DINO" 
            />
            <CaseStudiesDetailsContent />
            <RelatedProjects />
            <Footer />
        </Layout>
    );
}
export default CaseDetails

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>The product:</span>
                        <a href="#" target="_blank" rel="noreferrer">DINO</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Projects</span>
                        World
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>The backend:</span>
                        <a href="https://hasura.io" target="_blank">Hasura</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>The frontend:</span>
                        AJF + Angular + Material
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-globe'></i>
                        </div>
                        <span>Project page</span>
                        <a href="https://www.dinoapp.io" target="_blank" rel="noreferrer">DINO Website</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Data Collection</span>
                            <h3>Data collection has never been easier</h3>.
                            <p>DINO is the data collection software developed by Gnucoop. Based on its predecessor DEWCO, 
                                DINO extends its functionality based on a more modern and functional technology.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Features</h3>
                                        <ul>
                                            <li>Offline-first</li>
                                            <li>Asynchronous data collection</li>
                                            <li>Forms customization</li>
                                            <li>Kobo compatibility</li>
                                            <li>Data export in xls,csv</li>
                                            <li>Connections between forms</li>
                                            <li>Custom reports and dashboards</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <p>Collecting data in a simple and effective way is the foundation of any activity in the field of sustainable development. Whether it is to improve understanding of phenomena or to measure the impact of one's actions, the ability to collect and manage data is at the heart of social work. Gnucoop has created a data collection software, DINO. The software, in its original version, was used to collect health data in UNHCR refugee camps. Today, the software has evolved and is used by different organizations in different contexts.</p>
                            <h3>Technology</h3>
                            <p>Dino is the result of over 2 years of development and refinement of Dewco's data collection software.
With its almost 400000 lines of code Dino is the application to collect and analyze data, measure project indicators or process and monitor the organization's Theory of Change.
</p>
                            <p>The software is divided, as all the latest generation applications, in 
                                <ul>
                                    <li>backend</li>
                                    <li>frontend</li>
                                </ul>
                            </p>
                            <h4>Hasura: the backend</h4>
                           <p> The backend is based on <a href="https://hasura.io">Hasura</a> open source software and allows you to create what are called offline first applications in a fast, stable and standard way.
Offline first applications are those web and mobile applications that have the property to work, in the same way, both with and without a stable connection.
                            </p>
                            <h4>Dino APP and AJF: the frontend</h4>
                            <p>The Dino frontend (both the web and mobile application) is written in <a href="https://angular.io/">Angular</a>, leveraging the power and simplicity of the Material framework, and is based on the open source <a href="https://github.com/gnucoop/ajf">AJF library</a>. 
AJF is the library, developed by Gnucoop and released as free software, during the iRHIS project with UNHCR. The library allows to standardize the definition of data collection forms and reports, reviewing and extending the concepts contained in the Open Rosa library, used by ODK and Kobo</p>.
                            <p>The Dino application also allows you to:
                                <ul>
                                <li>Create data collection forms using XLSFORM and Formbuilder.</li>
                                <li>Define custom users and roles.</li>
                                <li>Define data aggregation metrics (Project, Area of intervention, Geolevel, Organization)</li>
                                <li>Extract data in xlsx/csv format</li>
                                </ul>
You can also create from DINO, native mobile applications for both Android and iOS.
</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
	                </div>
        </section>
    )
}
const RelatedProjects = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>More Services You Might Like</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="/data-analysis">
                                    Data Analysis
                                </Link>
                            </h3>
                            <p>We dig the mines of information composed by your data. You might not even be fully aware of the potential richness you have at your disposal, we still work hard to look for those information nuggets you surely have.</p>

                            <Link to="/data-analysis" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/app-development">
                                    APP development
                                </Link>
                            </h3>
                            <p>We create web and mobile applications for you to disseminate your outputs, engage with your stakeholders, increase your impact and manage your data.</p>
                            
                            <Link to="/app-development" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon3} alt="about" />
                            </div>
                            <h3>
                                <Link to="/digital-transformation">
                                Digital transformation
                                </Link>
                            </h3>
                            <p>
We support your organization towards an efficient and effective use of technology, through consultancy, training and the development of the IT solutions that best fit your needs.</p>
                            
                            <Link to="/digital-transformation" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
